import React, { useEffect, useState } from 'react';
import { Device } from '../../types';
import Chart from '../../components/Chart';
import { ChartData, MeasurementValue } from '../DataValues';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import DevicesInfo from '../../components/ekz/DevicesInfo';
import axios from 'axios';
import { getDates } from '../../util';

interface DeviceDashboardProps {
  serverUrl: string;
  devices: Device[];
}

const DeviceDashboard: React.FC<DeviceDashboardProps> = ({ serverUrl, devices }) => {
  const { device_id } = useParams();
  const device: Device | undefined = getDevice();
  const [consumptionData, setConsumptionData] = useState<MeasurementValue[]>([]);
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

  function getDevice(): Device | undefined {
    const id = Number(device_id);
    if (device_id === undefined || isNaN(id)) {
      return undefined;
    } else {
      return devices.find((device) => device.device_id === id);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    const fetchConsumption = async () => {
      try {
        const measResponse = await axios.get(`${serverUrl}/measurement/${device_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            measurement_name: 'Power'
          }
        });
        const { measurement_id } = measResponse.data;
        const { today, ereyesterday } = getDates();
        try {
          const valsResponse = await axios.get(
            `${serverUrl}/measurement_values/${measurement_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              },
              params: { start: ereyesterday, end: today }
            }
          );
          setConsumptionData(valsResponse.data);
        } catch (error) {
          console.error('Error fetching consumption data:', error);
        }
      } catch (error) {
        console.error('Error fetching consumption data:', error);
      }
    };

    if (token !== '') {
      fetchConsumption();
    }
  }, [device_id, serverUrl]);

  useEffect(() => {
    const newChartData: ChartData = {
      consumptionValue: consumptionData,
      productionValue: null,
      flexibilityValue: null,
      consumptionForecast: null,
      productionForecast: null,
      flexibilityForecast: null,
      events: null,
      invitations: null
    };
    setChartData(newChartData);
  }, [consumptionData]);

  return (
    <Grid container spacing={3}>
      <Grid sm={4}>{device ? <DevicesInfo device={device} /> : null}</Grid>
      <Grid sm={12}>
        {chartData ? (
          <Chart
            data={chartData}
            unit={'W'}
            headingText="Recent consumption"
            nDecimals={0}
            timeRangeButtons={[
              {
                type: 'day',
                count: 1,
                text: '1d'
              },
              {
                type: 'all',
                text: 'All'
              }
            ]}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default DeviceDashboard;
