import { Chip } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';

const StatusChip = ({ status }) => {
  const chipConfig = {
    online: {
      icon: <CheckCircle />,
      label: 'Online',
      bgcolor: '#24dac830',
      iconColor: '#23dac8',
      textColor: '#18978a'
    },
    offline: {
      icon: <Error />,
      label: 'Offline',
      bgcolor: '#ffd5d5ba',
      iconColor: '#ff4d4d',
      textColor: '#8b0000'
    }
  };

  const { icon, label, bgcolor, iconColor, textColor } = chipConfig[status] || chipConfig.noData;

  return (
    <Chip
      icon={icon}
      label={label}
      sx={{
        bgcolor: bgcolor,
        color: textColor,
        '& .MuiChip-icon': {
          color: iconColor,
          fontSize: '22px',
          marginRight: '-10px',
          marginLeft: '10px'
        }
      }}
    />
  );
};

export default StatusChip;
