import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import { Site } from '../../types';
import StatusChip from '../ekz/StatusChip';

interface TableProps {
  sites: Site[];
  setHighlightedSite: React.Dispatch<React.SetStateAction<number | null>>;
}

const SitesTable: React.FC<TableProps> = ({ sites, setHighlightedSite }) => {
  const tableEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const displayedSites = sites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer ref={tableEl} style={{ height: '700px', overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Site ID</b>
              </TableCell>
              <TableCell align="center">
                <b>Site name</b>
              </TableCell>
              <TableCell align="center">
                <b>Canton</b>
              </TableCell>
              <TableCell align="center">
                <b>Postcode</b>
              </TableCell>
              <TableCell align="center">
                <b>Status</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedSites.map((site) => (
              <TableRow
                key={site.site_id}
                hover={true}
                onMouseEnter={() => setHighlightedSite(site.site_id)}
                onMouseLeave={() => setHighlightedSite(null)}
                onClick={() => navigate(`/site/${site.site_id}/details`)}
              >
                <TableCell align="center">{site.site_id}</TableCell>
                <TableCell align="center">{site.name}</TableCell>
                <TableCell align="center">{site.params.canton}</TableCell>
                <TableCell align="center">{site.postcode}</TableCell>
                <TableCell align="center">
                  {site.params.site_not_responding ? (
                    <StatusChip status="offline" />
                  ) : (
                    <StatusChip status="online" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={sites.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default SitesTable;
