import React from 'react';
import { Device, Site } from '../../types';
import Chart from '../../components/Chart';
import { ChartData } from '../DataValues';
import Grid from '@mui/material/Unstable_Grid2';
import DevicesTable from '../../components/DevicesTable';
import SiteInfo from '../../components/ekz/SiteInfo';

interface SiteContentProps {
  chartData: ChartData | undefined;
  devices: Device[];
  siteInfo: Site | null;
  weeklyChartData: ChartData | undefined;
}

const SiteContent: React.FC<SiteContentProps> = ({
  chartData,
  devices,
  siteInfo,
  weeklyChartData
}) => {
  const hasForecast: boolean =
    !!chartData &&
    ((chartData.consumptionForecast !== null && chartData.consumptionForecast.length > 0) ||
      (chartData.productionForecast !== null && chartData.productionForecast.length > 0));
  return (
    <Grid container spacing={3}>
      <Grid sm={5}>{devices.length > 0 ? <DevicesTable devices={devices} /> : null}</Grid>
      <Grid sm={3}>{siteInfo ? <SiteInfo site={siteInfo} /> : null}</Grid>
      <Grid sm={12}>
        {chartData && (
          <Chart
            data={chartData}
            unit={'W'}
            headingText={hasForecast ? '48h past data & 72h forecast' : '48h past data'}
            nDecimals={0}
            timeRangeButtons={[
              {
                type: 'day',
                count: 1,
                text: '1d'
              },
              {
                type: 'all',
                text: 'All'
              }
            ]}
          />
        )}
      </Grid>
      <Grid sm={12}>
        {weeklyChartData && (
          <Chart
            data={weeklyChartData}
            unit={'W'}
            headingText="Historical data"
            nDecimals={0}
            timeRangeButtons={[
              {
                type: 'day',
                count: 3,
                text: '3d'
              },
              {
                type: 'day',
                count: 7,
                text: '1w'
              },
              {
                type: 'month',
                count: 1,
                text: '1m'
              },
              {
                type: 'month',
                count: 3,
                text: '3m'
              },
              {
                type: 'all',
                text: 'All'
              }
            ]}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SiteContent;