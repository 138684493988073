import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Avatar, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { BigCard } from '../../components/TiltCard';
import { calculateMapCenter } from '../../components/Map';
import { Site } from '../../types';
import SitesTable from '../../components/ekz/SitesTable';
import PlaceIcon from '@mui/icons-material/Place';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface SitesProps {
  serverUrl: string;
  sites: Site[];
}

const Sites: React.FC<SitesProps> = ({ sites, serverUrl }) => {
  const [sitesNotResponding, setSitesNotResponding] = useState<number>(0);
  const [center, setCenter] = useState<[number, number] | null>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [highlightedSite, setHighlightedSite] = useState<number | null>(null);

  useEffect(() => {
    if (sites.length !== 0) {
      setCenter(calculateMapCenter(sites));
    }
  }, [sites]);

  useEffect(() => {
    let sites_not_responding = 0;
    sites.forEach((site) => {
      if (site.params.site_not_responding === true) {
        sites_not_responding += 1;
      }
    });
    setSitesNotResponding(sites_not_responding);
  }, [sites]);

  if (sites.length === 0 || center === null) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '40px' }}
      >
        <CircularProgress />
      </Stack>
    );
  } else {
    return (
      <Grid container spacing={4}>
        <Grid sm={12}>{getSummaryNumbers(sites, sitesNotResponding)}</Grid>
        <Grid sm={12} xs={12}>
          <BigCard>
            <SitesTable sites={sites} setHighlightedSite={setHighlightedSite}></SitesTable>
          </BigCard>
        </Grid>
      </Grid>
    );
  }
};

function getSummaryNumbers(sites: Site[], sitesNotResponding: number) {
  const iconsSize = 33;

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack direction={'row'} justifyContent="space-around" alignItems="center" spacing={0}>
        {getLabelledIcon(
          <PlaceIcon sx={{ fontSize: iconsSize }} />,
          'Number of sites',
          `${sites.length}`
        )}
        {getLabelledIcon(
          <PriorityHighIcon sx={{ fontSize: iconsSize }} />,
          'Sites not responding',
          `${sitesNotResponding}`
        )}
      </Stack>
    </Paper>
  );
}

function getLabelledIcon(icon: ReactElement, label: string, value: string) {
  const circlesSize = 56;

  return (
    <Stack direction={'column'} style={{ alignItems: 'center' }}>
      <Avatar sx={{ width: circlesSize, height: circlesSize, bgcolor: '#22c9b9D0' }}>{icon}</Avatar>
      <Typography sx={{ paddingTop: '5px' }}>{label}</Typography>
      <Typography variant="h5" sx={{ paddingTop: '2px' }}>
        <b>{value}</b>
      </Typography>
    </Stack>
  );
}

export default Sites;
