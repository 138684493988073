import React from 'react';
import { Device } from '../../types';
import { SmallCard } from '../TiltCard';
import { Paper, Stack, TextField } from '@mui/material';

interface DeviceDashboardProps {
  device: Device;
}

const DeviceInfo: React.FC<DeviceDashboardProps> = ({ device }) => {
  return (
    <SmallCard>
      <Stack spacing={2} alignItems="flex-start">
        <Paper elevation={0} sx={{ typography: 'h6' }}>
          Device {device.name} details
        </Paper>
        <TextField
          id="device-name"
          label="Name"
          defaultValue={device.name}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          id="device-type"
          label="Device Type"
          defaultValue={device.type}
          InputProps={{
            readOnly: true
          }}
        />

        <TextField
          id="device-params"
          label="Details"
          defaultValue={JSON.stringify(device.params)}
          InputProps={{
            readOnly: true
          }}
        />
      </Stack>
    </SmallCard>
  );
};
export default DeviceInfo;
