import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import axios from 'axios';
import { Device, Site } from '../../types';
import SiteContent from './SiteContent';
import DeviceDashboard from './DeviceDashboard';
import { MeasurementValue, Prediction, ChartData, Invitation } from '../DataValues';

import { getDates } from '../../util';

interface SiteDashboardProps {
  serverUrl: string;
}

const SiteDashboard: React.FC<SiteDashboardProps> = ({ serverUrl }) => {
  const { site_id } = useParams();
  const [consumptionData, setConsumptionData] = useState<MeasurementValue[]>([]);
  const [productionData, setProductionData] = useState<MeasurementValue[]>([]);
  const [consumptionForecast, setConsumptionForecast] = useState<Prediction[]>([]);
  const [productionForecast, setProductionForecast] = useState<Prediction[]>([]);
  const [consumptionWeekly, setConsumptionWeekly] = useState<MeasurementValue[]>([]);
  const [productionWeekly, setProductionWeekly] = useState<MeasurementValue[]>([]);
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
  const [weeklyChartData, setWeeklyChartData] = useState<ChartData | undefined>(undefined);
  const [devices, setDevices] = useState<Device[]>([]);
  const [siteInfo, setSiteInfo] = useState<Site | null>(null);
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    const token = localStorage.getItem('user_token');

    const fetchConsumption = async () => {
      try {
        const devResponse = await axios.get(`${serverUrl}/devices/${site_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const storageEreYesterday: { [key: string]: number } = {};
        const storageOneYearAgo: { [key: string]: number } = {};
        devResponse.data.forEach(async (device: Device) => {
          const { device_id } = device;
          try {
            const measResponse = await axios.get(`${serverUrl}/measurement/${device_id}`, {
              headers: {
                Authorization: `Bearer ${token}`
              },
              params: {
                measurement_name: 'Power'
              }
            });
            const { measurement_id } = measResponse.data;
            const { today, ereyesterday, thirdDay, oneYearAgo } = getDates();

            try {
              const valsResponse = await axios.get(
                `${serverUrl}/measurement_values/${measurement_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  },
                  params: { start: ereyesterday, end: today }
                }
              );

              valsResponse.data.forEach((val: MeasurementValue) => {
                if (val.timepoint in storageEreYesterday) {
                  storageEreYesterday[val.timepoint] =
                    storageEreYesterday[val.timepoint] + val.value;
                } else {
                  storageEreYesterday[val.timepoint] = val.value;
                }
              });
            } catch (error) {
              console.error('Error fetching consumption data:', error);
            }

            try {
              const predResponse = await axios.get(`${serverUrl}/predictions/${measurement_id}`, {
                headers: {
                  Authorization: `Bearer ${token}`
                },
                params: { start: today, end: thirdDay }
              });

              setConsumptionForecast(predResponse.data);
            } catch (error) {
              console.error('Error fetching consumption data:', error);
            }

            try {
              const aggResponse = await axios.get(
                `${serverUrl}/measurement_values/${measurement_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  },
                  params: { start: oneYearAgo, end: today }
                }
              );

              aggResponse.data.forEach((val: MeasurementValue) => {
                if (val.timepoint in storageOneYearAgo) {
                  storageOneYearAgo[val.timepoint] = storageOneYearAgo[val.timepoint] + val.value;
                } else {
                  storageOneYearAgo[val.timepoint] = val.value;
                }
              });
            } catch (error) {
              console.error('Error fetching consumption data:', error);
            }
          } catch (e) {
            console.error('Error fetching measurement data:', e);
          }

          const consumption: MeasurementValue[] = [];
          Object.keys(storageEreYesterday).forEach((key) => {
            consumption.push({ timepoint: key, value: storageEreYesterday[key] });
          });
          consumption.sort(
            (a, b) => new Date(a.timepoint).getTime() - new Date(b.timepoint).getTime()
          );

          setConsumptionData(consumption);

          const consumptionHistorical: MeasurementValue[] = [];
          Object.keys(storageOneYearAgo).forEach((key) => {
            consumptionHistorical.push({ timepoint: key, value: storageOneYearAgo[key] });
          });
          consumptionHistorical.sort(
            (a, b) => new Date(a.timepoint).getTime() - new Date(b.timepoint).getTime()
          );

          setConsumptionWeekly(consumptionHistorical);
        });
      } catch (error) {
        console.error('Error fetching measurement data:', error);
      }
    };

    const fetchProduction = async () => {
      try {
        const devResponse = await axios.get(`${serverUrl}/device/${site_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { name: 'Production' }
        });
        const { device_id } = devResponse.data;

        const measResponse = await axios.get(`${serverUrl}/measurement/${device_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { measurement_name: 'Production' }
        });
        const { measurement_id } = measResponse.data;

        const { today, ereyesterday, thirdDay, oneYearAgo } = getDates();
        const valsResponse = await axios.get(`${serverUrl}/measurement_values/${measurement_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { start: ereyesterday, end: today }
        });
        setProductionData(valsResponse.data);

        const predResponse = await axios.get(`${serverUrl}/predictions/${measurement_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { start: today, end: thirdDay }
        });
        setProductionForecast(predResponse.data);

        const aggResponse = await axios.get(`${serverUrl}/measurement_values/${measurement_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { start: oneYearAgo, end: today }
        });
        setProductionWeekly(aggResponse.data);
      } catch (error) {
        console.error('Error fetching production data:', error);
      }
    };

    const fetchSiteInfo = async () => {
      try {
        const response = await axios.get(`${serverUrl}/site`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { site_id: site_id }
        });
        let site = response.data as Site;

        try {
          const prodResponse = await axios.get(`${serverUrl}/device/${site_id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: { name: 'Production' }
          });
          site.peak_power = prodResponse.data['params']['peak power'];
        } catch (error) {
          console.error(`Could not fetch peak power for site ${site_id}`, error);
        }

        setSiteInfo(site);
      } catch (error) {
        console.error('Error fetching site information:', error);
      }
    };

    const fetchDevices = async () => {
      try {
        const response = await axios.get(`${serverUrl}/devices/${site_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setDevices(response.data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    const fetchInvitations = async () => {
      const { thirdDay, oneYearAgo } = getDates();

      try {
        const eventsReponse = await axios.get(`${serverUrl}/invitations_result/${site_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            start: oneYearAgo,
            end: thirdDay
          }
        });

        setInvitations(eventsReponse.data);
      } catch (error) {
        console.error('Error fetching invitations data:', error);
      }
    };

    if (token !== '') {
      fetchConsumption();
      fetchProduction();
      fetchSiteInfo();
      fetchDevices();
      fetchInvitations();
    }
  }, [site_id, serverUrl]);

  useEffect(() => {
    const newChartData: ChartData = {
      consumptionValue: consumptionData,
      productionValue: productionData,
      flexibilityValue: null,
      consumptionForecast: consumptionForecast,
      productionForecast: productionForecast,
      flexibilityForecast: null,
      events: null,
      invitations: invitations
    };
    setChartData(newChartData);
  }, [consumptionData, productionData, consumptionForecast, productionForecast, invitations]);

  useEffect(() => {
    const some_data_fetched: boolean = consumptionWeekly.length > 0 || productionWeekly.length > 0;

    if (some_data_fetched) {
      const aggChartData: ChartData = {
        consumptionValue: consumptionWeekly,
        productionValue: productionWeekly,
        flexibilityValue: null,
        consumptionForecast: null,
        productionForecast: null,
        flexibilityForecast: null,
        events: null,
        invitations: invitations
      };
      setWeeklyChartData(aggChartData);
    }
  }, [consumptionWeekly, productionWeekly, invitations]);

  return (
    <Routes>
      <Route
        path="/details"
        element={
          <SiteContent
            chartData={chartData}
            devices={devices}
            siteInfo={siteInfo}
            weeklyChartData={weeklyChartData}
          />
        }
      />
      <Route
        path="/device/:device_id"
        element={<DeviceDashboard serverUrl={serverUrl} devices={devices} />}
      />
    </Routes>
  );
};

export default SiteDashboard;
